import './index.scss';
import logoESV from '../../../assets/images/LogoExcelTrim.png'
import { /*useEffect,*/ useRef } from 'react';
// import gsap from 'gsap'
// import DrawSVGPlugin from 'gsap-trial/DrawSVGPlugin'


const Logo = () => {
    const bgRef = useRef();
    // const outlineLogoRef = useRef();
    const solidLogoRef = useRef();

    // useEffect(() => {
    //     gsap.registerPlugin(DrawSVGPlugin)

    //     gsap
    //     .timeline()
    //     .to(bgRef.current, {
    //         duration: 1,
    //         opacity: 1,
    //     })
    //     .from(outlineLogoRef.current, {
    //         drawSVG: 0,
    //         duration: 20,
    //     })

    //     gsap.fromTo(
    //         solidLogoRef.current,
    //         {
    //             opacity: 0,
    //         },
    //         {
    //             opacity: 1,
    //             delay: 4,
    //             duration: 4,
    //         }
    //     )
    // },[])
    
    return (
        <div className='logo-container' ref={bgRef}>
            <img className='solid-logo1' ref={solidLogoRef} src={logoESV} alt='Excel SolutionsV' />
            <img className='solid-logo2' ref={solidLogoRef} src={logoESV} alt='Excel SolutionsV' />
            
            {/* <svg 
                width='1264px'
                height='458px'
                version='1.2'
                viewBox="0 0 559 897"
                xmlns="http://www.w3.org/2000/svg" 
                >
                <g
                    className='svg-container'
                    transform='translate(0 457) scale(.1 -.1)'
                    fill='none'
                    >
                    <path ref={outlineLogoRef} d="M175.48,450.44c-17.42-5.31-35.21-9.27-52.85-13.73-20.67-5.23-41.36-10.46-62.07-15.53-16.22-3.97-32.44-7.95-48.66-11.91-4.03-.99-4.94-3.01-4.79-6.65,.54-13.45-1-26.95,1-40.37,.07-.49,.02-1,.03-1.5,.33-33.78,.07-67.58,1.24-101.33,.66-19.14-.29-38.29,.97-57.36,1.16-17.48-.22-34.96,.94-52.37,1.22-18.3,.55-36.58,.84-54.87,.11-6.9,0-6.88-7.16-7-1.49-.02-2.98-.22-4.47-.34v-2c3.99-.1,7.98-.25,11.96-.29,1.81-.02,3.3,.35,3.06,2.8-1.51,15.28,.07,30.62-.76,45.9-1.13,20.62-.04,41.29-1.16,61.86-.97,17.82,.32,35.63-.91,53.37-1.35,19.47,.24,38.97-1.04,58.36-1.2,18.15,.23,36.3-.99,54.37-.88,12.98-.4,25.93-.88,38.88-.14,3.63,1.03,5.4,4.61,6.24,16.1,3.77,32.19,7.58,48.22,11.64,17.65,4.47,35.12,9.71,52.86,13.74,20.66,4.68,41.05,10.36,61.64,15.23,4.59,1.09,9.6,.47,14.42,.49,10.33,.05,20.66-.14,30.99,.1,3.84,.09,6.95-1.28,9.48-3.67,4.3-4.06,8.66-8.02,13.42-11.55,2.48-1.84,3.69-4.43,3.73-7.74,.22-18.96,.62-37.93,1.24-56.86,.5-15.13-.3-30.31,.91-45.36,1.12-13.99-.2-27.98,.96-41.87,1.25-14.98-.17-29.98,1.03-44.87,1.18-14.65-.19-29.31,.97-43.87,1.21-15.14-.17-30.31,1.04-45.37,1.14-14.15-.18-28.31,.95-42.37,1.21-14.98,.06-29.95,1.05-44.87,.49-7.48,.85-14.93,.71-22.39-.07-3.48,1.84-5.7,3.82-7.78,4.33-4.52,8.83-8.92,13.57-13,3.42-2.93,2.51-6.57,2.69-10.13,.1-1.99,.23-3.98,.35-5.97,.67,0,1.33,0,2,0,.11,3.99,.16,7.98,.36,11.96,.11,2.24-1.03,3.75-2.48,5.2-4.84,4.82-9.59,9.72-14.52,14.45-2.05,1.97-3.04,4.05-3.06,6.98-.09,18.95-.63,37.91-1.24,56.84-.49,14.96,.33,29.97-.89,44.85-1.18,14.48,.21,28.97-.97,43.36-1.25,15.14,.2,30.3-1.02,45.36-1.18,14.65,.22,29.31-.99,43.86-1.2,14.48,.21,28.97-1,43.36-1.22,14.48,.2,28.97-.99,43.36-1.22,14.81-.03,29.61-1.01,44.36-.5,7.64-.79,15.25-.8,22.88,0,2.77-1.24,5.15-3.07,6.56-6.92,5.34-12.71,12.06-20.3,16.56h-52.99Z"/>
                    <path ref={outlineLogoRef} d="M1604.3,389.45c-2.73-4.48-5.29-9.05-9.43-12.53-3.71-3.12-7.8-5.08-12.63-5.12-9.98-.09-19.97-.16-29.95,0-10.73,.18-21.46,10.93-21.64,21.67-.16,9.98-.16,19.97,0,29.95,.17,10.74,10.91,21.49,21.64,21.67,9.98,.17,19.97,.22,29.95-.02,9.32-.22,14.94-6.26,19.63-13.46,.88-1.35,.74-3.29,2.42-4.17v9c-8.97,11.75-21.42,13.14-34.9,12.07-6.11-.48-12.32-.44-18.45-.01-10.23,.72-24.17-13.44-23.73-23.59,.48-10.95,.48-21.96,0-32.92-.45-10.15,13.49-24.14,23.73-23.62,9.95,.5,19.96,.3,29.93,.04,7.48-.2,13.66,2.05,18.63,7.72,1.41,1.61,3.19,2.9,4.79,4.33v9Z"/>
                    <path ref={outlineLogoRef} d="M1286.34,450.44c-10.3-3.2-20.92-14.96-19.53-27.53,.96-8.72,.77-17.69,.04-26.46-.88-10.51,14-25.32,24.51-24.52,9.94,.75,20.03,.75,29.96,0,10.51-.8,25.39,14.01,24.51,24.52-.73,8.77-.93,17.73,.04,26.46,1.38,12.56-9.24,24.32-19.53,27.53h-39.99Zm56.64-38.86c0-4.67-.33-9.36,.07-13.99,.9-10.5-12.32-23.46-22.7-22.9-9.3,.51-18.68,.51-27.98,0-10.41-.58-23.39,12.38-22.78,22.87,.54,9.13,.6,18.36-.01,27.48-.72,10.73,12.45,23.87,23.02,23.15,8.29-.56,16.74-.88,24.97,.07,13.06,1.51,27.5-12.5,25.51-25.7-.54-3.58-.08-7.32-.08-10.99Z"/>
                    <path ref={outlineLogoRef} d="M1457.32,450.44c-10.3-3.2-20.92-14.96-19.53-27.53,.96-8.72,.77-17.69,.04-26.46-.88-10.51,14-25.32,24.51-24.52,9.94,.75,20.03,.75,29.96,0,10.51-.8,25.39,14.01,24.51,24.52-.73,8.77-.93,17.73,.04,26.46,1.38,12.56-9.24,24.32-19.53,27.53h-39.99Zm19.75-2.35c4.83,0,9.68-.27,14.49,.06,10.2,.7,23.02-12.27,22.5-22.58-.47-9.31-.53-18.68,.01-27.98,.61-10.51-12.37-23.46-22.76-22.88-9.3,.51-18.68,.51-27.98,0-10.4-.57-23.42,12.38-22.76,22.88,.53,8.3,.88,16.75-.07,24.97-1.54,13.27,12.59,27.58,25.58,25.62,3.58-.54,7.32-.08,10.99-.08Z"/>
                    <path ref={outlineLogoRef} d="M.5,70.49c2.64-.97,5.25-2.06,7.94-2.87,2.71-.82,4.93-1.72,4.85-5.24-.06-2.58,1.96-3.42,4.14-3.18,18.24,1.99,34.24-6.18,51.07-10.88C117.78,34.55,165.99,17.42,214.79,2.15c1.58-.49,3.12-1.1,4.68-1.65,2.67,0,5.33,0,8,0-7.2,3.73-15.08,5.63-22.71,8.12-39.56,12.89-79.03,26.13-118.91,37.97-16.03,4.76-31.8,10.39-47.99,14.6-5.72,1.49-11.59,.47-17.39,.59-2.94,.07-4.92,.57-4.68,4.08,.08,1.16-.12,2.43-1.53,2.83-4.57,1.31-8.77,4-13.76,3.8,0-.67,0-1.33,0-2Z"/>
                    <path ref={outlineLogoRef} d="M1432.67,408.37c0,4.49,0,8.98,0,13.48-.01,11.8-7.96,22.53-19.3,25.34-3.64,.9-7.57,.8-11.38,.8-9.12,0-18.27,.27-27.34-.45-9.07-.72-21.54-14.1-20.83-24.24,.69-9.93,.72-20,0-29.93-.77-10.43,14.01-25.24,24.56-24.43,9.92,.76,20,.76,29.92,0,10.52-.8,25.63,14.02,24.46,24.47-.55,4.93-.1,9.97-.09,14.96Zm-71.41,.12c0-3,0-6,0-8.99,0-3-.11-6,.04-8.99,.42-8.37,5.55-13.84,13.86-14.03,12.15-.28,24.32-.28,36.47,.01,8.08,.2,13.46,5.57,13.66,13.65,.3,12.15,.29,24.32,.01,36.47-.19,8.31-5.66,13.44-14.03,13.86-2.82,.14-5.66-.02-8.49,.08-1.22,.04-3.09-.46-3.2,1.42-.12,1.91,1.32,2.77,3.21,3.1,13.4,2.31,27.08-9.05,27.17-22.69,.06-9.33-.38-18.67,.12-27.97,.56-10.36-12.38-23.35-22.9-22.72-9.13,.55-18.34,.59-27.46-.01-10.71-.71-23.82,12.44-23.14,23.01,.57,8.96,.68,18.02-.02,26.96-.87,11.05,12.54,24.78,23.62,23.51,3.95-.45,7.99,0,11.98-.11,1.52-.04,3.79,.68,3.98-1.78,.2-2.61-2.04-2.67-3.88-2.7-5.49-.08-10.99,.08-16.49-.1-8.87-.28-14.19-5.61-14.46-14.49-.17-5.82-.03-11.66-.04-17.49Zm31.77,28.65c5.32,0,10.64,.11,15.96-.03,8.17-.21,12.83-4.63,12.98-12.67,.2-10.64,.19-21.29,0-31.93-.14-8.07-4.77-12.61-12.89-12.74-10.48-.16-20.96-.16-31.43,0-8.17,.12-12.83,4.62-12.98,12.66-.2,10.64-.19,21.29,0,31.93,.14,8.07,4.77,12.52,12.9,12.75,5.15,.14,10.31,.03,15.47,.03Z"/>
                    <path ref={outlineLogoRef} d="M827.84,317.8c-10.74,1.16-22.38-1.99-33.94-5.92-4.28-1.45-6.12-3.86-5.92-8.45,.29-6.48,.04-12.99,.1-19.49,.02-1.94-.51-4.33,1.79-5.3,2.4-1.01,4.71-.66,6.95,1.13,5.35,4.27,11.34,7.5,17.8,9.82,7.68,2.76,15.59,3.33,23.55,1.92,4.04-.71,8.24-2.66,8.26-7.34,.02-4.7-4.32-6.23-8.22-7.38-8.74-2.57-17.9-3.65-26.41-7.03-10.27-4.08-19.2-10.16-21.66-21.58-5.05-23.42,3.38-38.85,26.8-45.76,17.91-5.29,36.18-3.16,53.86,2.9,3.31,1.14,5.12,3.29,4.99,7.1-.21,5.99,.03,12-.09,17.99-.05,2.37,1.02,5.31-1.72,6.84-3.02,1.68-5.48-.42-7.69-2.07-6.11-4.58-13.01-7.34-20.37-8.91-6.55-1.4-13.09-1.64-19.25,1.91-2.31,1.33-4.19,2.7-3.89,5.73,.29,2.88,2.1,4.28,4.59,5.27,7.13,2.83,14.76,3.77,22.07,5.9,6.85,2,13.49,4.27,19.39,8.64,10.88,8.05,13.01,19.22,11.48,31.47-2.49,19.92-21.52,31.28-39.83,32.55-3.65,.25-7.33,.04-12.64,.04Zm1.82-2.86c9.03,.98,17.73-.69,26.26-3.87,20.67-7.71,28.98-33.59,15.85-50.5-5.87-7.56-14.78-10.28-23.63-12.79-7.33-2.07-14.95-3.01-22.06-5.9-6.91-2.81-8.62-10.88-2.91-15.02,5.11-3.7,11.03-4.93,17.41-4.3,10.67,1.06,20.28,4.73,28.88,11.08,2.55,1.88,3.56,1.15,3.57-1.79,0-5.83-.18-11.67,.08-17.48,.17-3.83-1.38-5.51-4.94-6.79-12.12-4.36-24.59-5.25-37.29-4.77-9.34,.36-17.86,3.16-25.72,8.21-18.06,11.61-17.14,38.96-3.95,49.3,6.61,5.18,14.16,7.72,22.07,9.84,7.47,2,15.54,2.21,22.19,6.82,5.13,3.55,5.54,8.57,1.31,13.13-2.89,3.12-6.54,4.29-10.64,4.71-15.17,1.58-28.44-3.07-40.42-12.07-1.16-.87-2.17-2.41-3.87-1.48-1.63,.89-1.06,2.67-1.07,4.09-.06,5.83,.14,11.66-.09,17.48-.14,3.4,1.17,5.13,4.33,6.22,11.21,3.86,22.49,7.11,34.63,5.87Z"/>
                    <path ref={outlineLogoRef} d="M330.11,258.47c0-16.66,.12-33.33-.08-49.99-.06-4.71,1.45-6.47,6.3-6.43,22.49,.19,44.99,.17,67.49,0,4.62-.03,6.29,1.67,6.03,6.17-.26,4.48-.26,9.01,0,13.49,.26,4.5-1.41,6.26-6.03,6.17-11.83-.22-23.67,.12-35.49-.16-4.49-.11-6.13,1.43-5.55,5.75,.15,1.15,.14,2.34,0,3.49-.46,3.86,1,5.32,5.04,5.23,10.66-.24,21.33,.08,31.99-.15,4.62-.1,6.29,1.67,6.03,6.17-.26,4.48-.26,9.01,0,13.49,.26,4.5-1.41,6.27-6.03,6.17-10.66-.23-21.33,.09-31.99-.15-4.05-.09-5.33,1.37-5.08,5.23,.26,3.98,.26,8.01,0,11.99-.25,3.87,1.04,5.31,5.08,5.23,11.83-.23,23.66-.1,35.49-.07,6.11,.02,6.43,.36,6.47,6.59,.03,4.83-.06,9.67,0,14.5,.04,3.11-1.3,4.59-4.47,4.59-23.5-.03-46.99-.05-70.49,.03-4.33,.02-4.75-2.51-4.74-5.87,.05-17.16,.02-34.33,.02-51.49Zm2.7,.54c0,16.16,.13,32.32-.09,48.47-.06,4.33,1.16,5.79,5.62,5.73,17.99-.23,35.98-.09,53.97-.09,14.96,0,14.78,0,14.89-14.87,.03-4.6-1.67-5.59-5.85-5.5-11.49,.23-22.99,.09-34.48,.08-6.74,0-6.75-.03-6.76-6.98-.01-4.83,.26-9.68-.08-14.49-.33-4.7,1.16-6.49,6.1-6.33,10.32,.34,20.66-.15,30.98,.21,4.97,.17,6.52-1.6,6.05-6.3-.32-3.13-.22-6.33-.02-9.49,.22-3.44-1.08-4.75-4.59-4.67-8.16,.18-16.32,.05-24.48,.05-14.23,0-14.1,0-14.04-14.29,.02-4.07,1.25-5.56,5.42-5.49,12.32,.21,24.65-.01,36.98,.12,3.37,.04,4.94-.96,4.72-4.54-.22-3.48-.27-7.01,.01-10.49,.35-4.19-1.31-5.42-5.42-5.39-21.15,.17-42.31,.2-63.46-.02-4.55-.05-5.6,1.56-5.55,5.8,.21,16.15,.08,32.31,.08,48.47Z"/>
                    <path ref={outlineLogoRef} d="M1476.55,317.66c-9.86,.94-19.17-.97-28.26-4.61-3.17-1.27-4.39-3.13-4.34-6.36,.09-5.66,0-11.32,.04-16.99,.01-1.96-.34-4.24,1.98-5.2,2.16-.9,4.41-.32,6.14,1.06,6.42,5.11,13.89,7.57,21.77,9.23,4.34,.91,8.18-.69,12.14-1.82,1.01-.29,1.79-1.05,1.65-2.2-.19-1.47-1.26-2.44-2.56-2.79-4.32-1.16-8.66-2.19-13.02-3.21-8.64-2.02-17.34-4.13-22.91-11.82-10.81-14.91-4.96-35.59,12.43-42.68,17.06-6.96,34.39-5.62,51.54,.28,2.53,.87,3.6,2.61,3.54,5.33-.11,5.16,0,10.33-.03,15.49-.04,6.52-3.6,8.55-9.25,4.92-5.41-3.47-11.2-5.63-17.4-7.06-3.98-.91-7.54,.18-11.14,1.46-1.13,.4-2.24,1.04-2.34,2.34-.13,1.62,1.23,2.3,2.44,2.82,5.37,2.31,11.18,2.97,16.81,4.3,7.24,1.71,13.86,4.57,18.87,10.27,8.54,9.72,7.28,31.64-7.59,40.63-9.53,5.76-19.69,7.58-30.5,6.62Zm-.86-2.55c14.82,0,19.68-1.03,27.65-5.58,10.45-5.97,15.38-15.44,13.49-27.06-1.79-10.98-9.72-15.93-19.58-18.83-6.52-1.91-13.39-2.4-19.7-5.11-2.48-1.06-4.39-2.79-4.32-5.54,.07-2.57,2.21-4.01,4.39-4.96,4.37-1.91,8.94-2.47,13.64-1.5,6.39,1.33,12.38,3.59,17.83,7.3,2.96,2.01,5,1.57,4.89-2.67-.11-4.5-.13-9,.01-13.5,.08-2.59-1-3.98-3.38-4.78-15.9-5.37-31.87-6.59-47.66,.05-15.93,6.7-21.08,24.12-11.45,37.96,5.3,7.62,13.84,9.16,22.06,11.21,4.18,1.04,8.41,1.89,12.52,3.17,2.67,.83,4.56,2.74,4.52,5.87-.04,3.07-2.11,4.16-4.67,5.02-4.83,1.64-9.58,2.07-14.68,1-7.44-1.57-14.28-4.14-20.35-8.8-2.42-1.86-4.31-1.77-4.24,2.1,.09,5.16,.06,10.33,0,15.5-.02,2.27,.77,3.51,3.06,4.43,8.77,3.5,17.74,5.49,25.96,4.74Z"/>
                    <path ref={outlineLogoRef} d="M433.25,228.12c12.9-3.6,20.07,2.99,25.7,13.63,3.58,6.76,4.92,6.81,8.13,.13,5.47-11.4,13.66-15.98,26.11-13.88,4.21,.71,8.65,.12,12.98,.14,1.7,0,3.47,.08,4.23,1.94,.7,1.71-.03,3.13-1.1,4.58-7.97,10.81-15.74,21.78-23.87,32.46-2.58,3.38-2.58,5.87-.07,9.19,7.69,10.2,15.18,20.55,22.64,30.92,1.39,1.93,3.72,3.91,2.35,6.67-1.41,2.85-4.38,1.84-6.75,1.88-7.33,.1-14.67-.17-21.99,.12-4.02,.16-6.53-1.27-8.53-4.67-2.44-4.14-5.54-7.9-7.89-12.09-2.29-4.09-3.62-2.39-5.33,.28-2.68,4.2-5.48,8.31-8.21,12.48-1.77,2.71-4.07,4.04-7.48,3.96-8.16-.19-16.33-.04-24.49-.08-1.79,0-3.83,.39-4.97-1.54-1.35-2.29,.63-3.83,1.7-5.3,7.9-10.87,15.83-21.72,23.98-32.4,2.45-3.21,2.27-5.54-.11-8.72-7.76-10.35-15.24-20.91-22.71-31.48-1.28-1.8-3.3-3.72-2.05-6.3,1.3-2.69,4.02-1.85,6.25-1.91,3.83-.1,7.66-.03,11.5-.03Zm75.15,2.7c-9.51,0-17.66,.15-25.79-.07-3.29-.09-5.2,1.19-6.86,3.84-3.34,5.35-6.9,10.56-10.4,15.8-1.56,2.33-2.92,2.75-4.67-.07-3.51-5.64-7.39-11.05-10.92-16.68-1.32-2.11-2.9-2.89-5.31-2.85-7.16,.1-14.33,0-21.5,.06-1.42,.01-3.39-.78-4.14,.73-.71,1.42,.92,2.69,1.74,3.83,7.92,11.07,15.87,22.12,23.9,33.1,1.88,2.57,2.32,4.51,.07,7.38-6.44,8.23-12.56,16.72-18.61,25.24-2.68,3.78-6.16,7.1-7.99,11.97,8.67,0,16.83-.11,24.98,.06,3.22,.07,5.2-1.1,6.86-3.85,2.92-4.82,6.2-9.43,9.37-14.1,3.12-4.58,3.84-4.59,6.83-.19,3.45,5.08,6.85,10.19,10.25,15.31,.83,1.26,1.53,2.68,3.31,2.68,9.24,.02,18.48,.01,27.51,.01,.41-1.66-.3-2.21-.77-2.86-7.78-10.76-15.42-21.62-23.41-32.22-3.18-4.22-4.07-7.36-.3-12.1,8.74-11,16.74-22.58,25.85-35.02Z"/>
                    <path ref={outlineLogoRef} d="M1087.97,310.46c-13.9,9.51-29.09,10.33-40.39,2.5-7.29-5.05-10.22-12.78-10.41-21.08-.43-19.48-.09-38.98-.18-58.47-.02-3.85,1.45-5.42,5.32-5.33,6.66,.16,13.33,.19,19.99,0,4.19-.12,5.53,1.7,5.48,5.69-.15,10.99,.13,21.99-.12,32.98-.12,5.37,1.09,10.55,1.27,15.87,.14,4.05,2.11,7.34,6.73,8,4.37,.62,8.12-1.1,11.51-3.5,1.45-1.03,.83-3.12,.84-4.74,.03-15.99,.15-31.98-.06-47.97-.06-4.78,1.59-6.56,6.37-6.35,6.65,.29,13.33,.18,19.99,.04,3.74-.08,5.47,1.22,5.45,5.19-.09,25.82-.08,51.64-.01,77.46,0,3.58-1.38,5.16-5.02,5.1-7.16-.12-14.33-.11-21.49,0-3.38,.06-5.61-.88-5.25-5.38Zm1.85-4.68c1.91,2.65-1.01,7.47,4.56,7.35,6.32-.14,12.66-.1,18.98,0,2.92,.04,3.67-1.41,3.67-4.03-.04-24.81-.05-49.62,0-74.43,0-2.76-1.07-3.91-3.85-3.86-5.66,.1-11.34,.29-16.98-.06-4.55-.29-5.63,1.5-5.57,5.77,.21,15.82,.07,31.64,.09,47.45,0,2.38,.07,4.82-2.29,6.2-3.76,2.21-7.81,3.87-12.25,3.53-5.95-.45-9.56-3.72-9.95-9.77-.34-5.29-1.42-10.5-1.29-15.86,.25-10.65-.09-21.32,.16-31.97,.1-4.22-1.39-5.59-5.47-5.34-4.65,.28-9.34,.32-13.98,0-4.49-.32-6.1,1.07-6.03,5.82,.27,17.31-.25,34.64,.2,51.95,.57,21.83,14.23,29.42,33.71,25.95,6.28-1.12,10.62-5.86,16.29-8.69Z"/>
                    <path ref={outlineLogoRef} d="M1431.67,281.19c0,9.49-.15,18.98,.07,28.46,.1,4.43-1.3,6.41-5.98,6.22-6.48-.27-12.98-.19-19.47-.03-3.83,.09-5.4-1.42-5.34-5.3,.14-9.82-.2-19.65,.12-29.46,.21-6.52-.98-12.9-1.24-19.36-.16-3.91-1.6-7.48-6.37-8.3-4.57-.79-8.49,.63-11.94,3.39-1.4,1.12-.81,3.14-.81,4.76-.03,15.98-.15,31.96,.06,47.94,.06,4.76-1.56,6.58-6.35,6.36-6.65-.29-13.32-.18-19.97-.04-3.7,.08-5.5-1.16-5.49-5.15,.09-25.8,.07-51.6,.01-77.41,0-3.51,1.21-5.24,4.93-5.18,7.32,.12,14.65,.03,21.97,.04,1.67,0,3.94-.12,4.28,1.73,.84,4.59,2.68,2.47,4.75,1.13,9.65-6.26,20.15-6.41,30.61-3.12,9.16,2.88,16.03,14.08,16.14,24.85,.1,9.49,.02,18.98,.02,28.47Zm-79.98-9.56c0,11.99,.19,23.99-.1,35.98-.11,4.51,1.46,5.85,5.77,5.58,4.98-.32,10.01-.32,14.98,0,4.34,.28,5.81-1.14,5.74-5.61-.25-14.99-.1-29.98-.09-44.97,0-8.67,7.12-14.08,15.85-12.25,5.28,1.11,8.12,4.35,8.68,9.49,.59,5.43,1.03,10.91,1.12,16.37,.17,10.82,.13,21.65,0,32.48-.03,3.15,.96,4.57,4.29,4.46,5.49-.17,11-.2,16.49,0,3.51,.13,4.63-1.23,4.61-4.67-.12-18.32,.28-36.65-.17-54.96-.34-13.99-9.98-25.25-25.44-24.84-8.1,.22-14.82,3.02-21.12,7.65-2.31,1.7-4.65,2.82-4.32-1.85,.2-2.92-1.33-3.73-3.97-3.69-5.83,.09-11.67,.19-17.49-.04-3.7-.15-4.96,1.18-4.91,4.89,.18,11.99,.07,23.98,.06,35.98Z"/>
                    <path ref={outlineLogoRef} d="M655.66,279.81c-7.49,0-14.98-.04-22.48,.03-4.65,.04-5.28,1.3-2.88,5.34,3.71,6.24,14.9,10.19,23.45,8.64,7.31-1.33,13.89-4.19,20.18-8.04,5.84-3.57,8.76-1.93,8.81,4.69,.05,5.49-.11,10.99,.04,16.48,.08,2.99-1.26,4.64-3.92,5.59-19.19,6.82-38.68,8.4-57.92,1.13-14.16-5.35-21.75-16.55-23.96-31.64-2.07-14.18-.4-27.52,7.99-39.38,7.45-10.53,17.99-16.02,30.87-16.46,6.46-.22,13.02-.83,19.43,.38,17.54,3.3,27.27,17.29,28.41,34.32,.31,4.64-.05,9.32,.08,13.98,.1,3.69-1.61,5.02-5.15,4.96-7.66-.11-15.32-.03-22.98-.03Zm-2.24-2.71c7.83,0,15.66-.07,23.48,.04,2.94,.04,4.31-.96,4.18-4.04-.15-3.66,.23-7.35-.08-10.99-1.4-16.05-8.66-27.95-23.3-31.86-9.9-2.64-20.16-2.56-30.06,.18-9.22,2.55-16.96,7.62-21.84,16.21-6.76,11.92-8.4,24.56-5.44,37.93,3.02,13.64,10.97,23.05,24.16,27.37,15.75,5.15,31.71,4.67,47.49-.28,8.08-2.53,8.04-2.68,8.04-10.9,0-3,.04-6-.01-8.99-.09-5.32-.85-5.83-5.31-2.98-8.18,5.21-16.94,8.33-26.75,8.02-11.74-.37-19.7-5.79-22.02-14.42-1.07-3.98-.27-5.43,3.97-5.32,7.82,.2,15.66,.06,23.48,.04Z"/>
                    <path ref={outlineLogoRef} d="M561.98,317.71c-11.56,.87-22.39-1.14-31.77-8-9.4-6.88-14.64-16.22-16.29-27.97-1.18-8.41-.75-16.55,1.24-24.62,4.71-19.05,20.22-30,39.17-30.91,10.43-.5,20.85-.55,30.93,3.55,4.48,1.83,6.94,3.89,6.58,8.99-.33,4.81,0,9.65-.1,14.48-.04,2.23,.81,5.14-2,6.14-2.87,1.02-5.74,.73-8.27-1.72-2.87-2.78-6.48-4.46-10.2-6.04-11.85-5.02-23.43,.96-25.31,15.73-.56,4.39-.53,8.9,.66,13.36,2.62,9.78,12.15,15.51,21.84,12.79,4.5-1.26,8.89-2.72,12.29-6.22,1.02-1.05,2.36-1.88,3.69-2.52,4.23-2.04,7.2-.23,7.29,4.47,.11,5.49-.2,11,.11,16.48,.25,4.42-1.8,6.66-5.61,8.26-7.84,3.29-15.95,4.42-24.25,3.75Zm.56-2.6c10.02,0,15.6-1.06,22.45-3.92,2.78-1.16,4.28-2.7,4.13-5.94-.22-4.82-.04-9.66-.07-14.49,0-1.29,.34-2.83-1.16-3.55-1.48-.71-2.51,.43-3.52,1.26-4.41,3.61-9.13,6.51-14.79,7.89-12.64,3.08-24.35-4.19-26.24-16.9-.65-4.4-.56-9.02-.11-13.46,1.29-12.63,11.3-20.6,23.54-18.71,6.34,.98,12.02,3.77,16.75,8.24,1.15,1.09,2.4,1.92,4.02,1.37,1.9-.65,1.5-2.39,1.52-3.82,.05-4.66-.17-9.34,.08-13.99,.19-3.67-1.53-5.1-4.75-6.45-10.18-4.26-20.85-4.71-31.4-3.58-14.67,1.58-26.68,8.27-32.81,22.54-4.56,10.63-5.37,21.74-2.76,33.1,1.56,6.8,4.34,12.87,9.21,17.92,9.73,10.1,22.03,12.95,35.92,12.5Z"/>
                    <path ref={outlineLogoRef} d="M1139.01,275.07c0-6.16-.12-12.32,.04-18.47,.08-3.25-.72-5.21-4.41-4.78-4.47,.51-5.9-1.76-5.67-5.92,.21-3.82,.18-7.66,.01-11.48-.17-3.84,1.44-5.68,5.29-5.27,3.81,.41,4.96-1.34,4.79-4.94-.22-4.65,.05-9.32-.07-13.98-.1-3.56,1.32-5.19,4.99-5.12,6.99,.12,13.98,.1,20.97,.01,3.4-.04,4.84,1.45,4.77,4.81-.09,4.32,.33,8.69-.09,12.97-.53,5.27,1.62,6.71,6.54,6.3,4.63-.38,9.31-.02,13.97-.08,3.11-.04,4.6,1.34,4.56,4.49-.06,4.49-.09,8.99,.01,13.48,.08,3.43-1.53,4.79-4.85,4.73-4.99-.09-9.99,.18-14.97-.07-3.82-.19-5.38,.97-5.25,5.05,.31,9.95-.25,19.92,1.05,29.85,.83,6.34,4.8,8.81,12.49,7.26q11.54-2.33,11.54,9.4c0,1,0,2,0,3,0,9.37,.07,10.01-8.64,10.97-9.36,1.04-18.94,1.61-28.27-.47-11.23-2.51-18.51-11.75-18.75-23.28-.13-6.16-.02-12.32-.03-18.47Zm2.71-2.17c0,6.33-.2,12.66,.05,18.98,.38,9.7,4.2,17.46,13.75,21.17,9.47,3.68,19.4,2.35,29.09,1.55,7.45-.61,7.39-1.33,7.4-9.15,0-2-.11-4,.02-5.99,.25-3.78-.85-5.38-4.88-3.75-1.96,.79-4.16,1.14-6.29,1.34-8.85,.82-11.81-2.18-13-10.87-1.11-8.15-.74-16.26-.82-24.39-.13-12.68-.03-12.52,12.52-12.72,14.7-.23,12.14,3.04,12.47-13.17,.06-3-1.09-4.19-4.1-4.12-5.49,.13-10.99-.08-16.48,.05-3.16,.08-4.55-1.08-4.46-4.34,.15-4.99-.11-9.99,.07-14.98,.12-3.35-.92-4.89-4.52-4.75-5.49,.21-10.99,.18-16.48,.01-3.24-.1-4.49,1.12-4.38,4.37,.17,5.16,.03,10.32,.02,15.48,0,2.11-.19,3.81-3.03,4.18-6.97,.93-6.95,1.06-6.95,8.21,0,1.33,.14,2.68-.02,3.99-.48,3.93,1.14,5.65,5.09,5.1,4.16-.58,5.09,1.64,4.98,5.3-.18,6.16-.05,12.32-.04,18.48Z"/>
                    <path ref={outlineLogoRef} d="M929.04,318.81c-7.49,.84-15.82-.87-23.58-5.25-10.73-6.05-16.38-15.72-19.01-27.34-2.35-10.39-1.75-20.75,1.13-31,3.82-13.59,12.76-22.35,25.93-26.65,12.57-4.11,25.44-3.71,37.6,1.07,15.41,6.06,23,18.71,24.43,34.7,.85,9.52,1.08,19.28-2.51,28.58-6.36,16.45-20,25.88-37.62,25.9-1.67,0-3.33,0-6.37,0Zm.23-2.7c3.05,0,4.88,.07,6.71-.01,15.11-.65,26.15-7.64,32.91-21.22,4.55-9.13,4.75-18.99,4.04-28.78-1.2-16.51-11.15-31.92-28.85-35.52-7.72-1.57-15.31-2.36-23.15-.82-14.45,2.83-25.08,10.16-29.94,24.53-3.3,9.77-4.12,19.81-2,29.9,2.42,11.45,7.59,21.25,18.44,27.02,7.2,3.83,14.84,5.76,21.84,4.91Z"/>
                    <path ref={outlineLogoRef} d="M1289.82,318.81c-7.49,.84-15.8-.93-23.55-5.33-10.72-6.09-16.28-15.8-18.92-27.41-2.33-10.23-1.68-20.46,1.07-30.53,3.76-13.78,12.79-22.71,26.19-27.03,12.58-4.05,25.46-3.65,37.6,1.19,15.37,6.13,22.94,18.78,24.29,34.8,.81,9.53,1.07,19.28-2.59,28.56-6.51,16.5-19.96,25.74-37.7,25.76-1.67,0-3.33,0-6.38,0Zm.36-2.7c3.05,0,4.88,.07,6.71-.01,15.11-.63,26.16-7.62,32.94-21.19,4.56-9.13,4.76-18.98,4.05-28.77-1.19-16.53-11.14-31.95-28.82-35.55-7.72-1.57-15.3-2.38-23.15-.84-14.45,2.83-25.1,10.13-29.96,24.5-3.3,9.76-4.15,19.81-2.02,29.9,2.42,11.45,7.57,21.26,18.41,27.04,7.2,3.83,14.83,5.78,21.83,4.93Z"/>
                    <path ref={outlineLogoRef} d="M726.76,257.12c0,17.66,.02,35.33,0,52.99,0,6.45-.26,6.67-6.87,6.7-6,.02-12,.03-18,0-6.54-.03-6.81-.27-6.82-6.75-.02-29.16,0-58.32,0-87.49,0-6.5,.2-13.01-.06-19.49-.19-4.65,1.72-6.19,6.19-6.02,6.49,.25,13,.25,19.49,0,4.53-.18,6.19,1.48,6.15,6.07-.18,18-.07,35.99-.07,53.99Zm-28.99-.16c0,17.33,.09,34.66-.07,51.99-.04,3.84,1.02,5.44,5.08,5.23,5.65-.3,11.33-.2,16.99-.03,3.3,.1,4.33-1.24,4.32-4.43-.07-35.16-.08-70.32,.01-105.48,.01-3.66-1.53-4.56-4.81-4.46-5.49,.17-11.01,.25-16.49-.02-4.01-.2-5.15,1.34-5.11,5.21,.16,17.33,.07,34.66,.07,51.99Z"/>
                    <path ref={outlineLogoRef} d="M1019.72,257.47c0,17.66-.11,35.33,.08,52.99,.05,4.7-1.44,6.64-6.3,6.42-6.65-.3-13.33-.18-19.99-.04-3.69,.08-5.52-1.12-5.51-5.14,.08-36.49,.08-72.99,0-109.48,0-4.01,1.82-5.21,5.51-5.13,6.83,.14,13.67,.21,20.49-.02,4.4-.15,5.82,1.66,5.78,5.91-.14,18.16-.06,36.33-.06,54.49Zm-2.7-.5c0-17.33-.09-34.66,.07-51.99,.04-3.86-1.04-5.43-5.1-5.22-5.65,.29-11.33,.2-16.99,.03-3.31-.1-4.32,1.27-4.31,4.44,.07,35.16,.07,70.32,0,105.48,0,3.18,1,4.54,4.31,4.44,5.66-.17,11.34-.26,16.99,.03,4.05,.21,5.13-1.37,5.09-5.22-.16-17.33-.07-34.66-.07-51.99Z"/>
                    <path ref={outlineLogoRef} d="M1560.13,323.81c-18.98,2.79-37.15-18.25-36.64-36.74,.49-17.63,16.81-35.98,36.62-36.01,19.43-.03,37.97,15.68,37,39.27-.62,15.1-16.7,36.32-36.98,33.48Zm.31-2.7c17.88,2.22,33.63-16.71,33.87-31.74,.32-20.12-14.96-35.33-33.75-35.42-18.95-.09-33.65,15.95-34.12,33.28-.49,17.89,16.33,35.88,34,33.87Z"/>
                    <path ref={outlineLogoRef} d="M1234.69,272.97c0,12.66-.11,25.33,.06,37.99,.06,4.26-1.39,6.06-5.78,5.91-6.82-.23-13.66-.16-20.49-.02-3.69,.07-5.52-1.12-5.51-5.13,.09-25.83,.07-51.66,.01-77.49,0-3.56,1.34-5.18,5-5.12,7.16,.12,14.33,.15,21.49-.01,3.92-.09,5.3,1.59,5.26,5.39-.12,12.83-.04,25.66-.04,38.49Zm-2.7,.5c0-12.33-.08-24.66,.05-36.99,.04-3.4-1.02-4.83-4.58-4.7-5.83,.21-11.67,.18-17.49,.01-3.31-.1-4.33,1.26-4.32,4.44,.08,24.5,.08,48.99,0,73.49-.01,3.17,1,4.54,4.31,4.44,5.66-.17,11.34-.26,16.99,.03,4.05,.21,5.16-1.36,5.1-5.22-.19-11.83-.07-23.66-.07-35.49Z"/>
                    <path ref={outlineLogoRef} d="M1219.75,197.14c3.66,0,7.33,.07,10.99-.02,3.25-.08,5.01,1.13,4.95,4.63-.09,5.49-.06,10.99-.02,16.49,.02,3.11-1.4,4.59-4.53,4.58-7.83-.03-15.65-.05-23.48,.01-3.06,.02-4.69-1.18-4.66-4.4,.05-5.66,.04-11.32,0-16.99-.02-2.89,1.32-4.31,4.24-4.29,4.16,.03,8.33,0,12.49,0v-.02Zm-.87,22.96c3.49,0,6.99-.03,10.48,0,2.51,.03,3.64-1.17,3.62-3.65-.03-3.99-.15-7.99,.05-11.98,.17-3.4-1.01-4.84-4.56-4.7-5.32,.21-10.65,.05-15.97,.05q-6.79,0-6.81,6.84-.03,13.45,13.18,13.42Z"/>
                    <path ref={outlineLogoRef} d="M199.75,374.93c1,12.5-1.35,25.89-1.02,39.38,.16,6.44-8.1,12.78-13.86,11.2-16.42-4.52-33.02-8.31-49.57-12.31-17.69-4.28-35.19-9.34-52.85-13.72-5.6-1.39-11.33-2.31-16.97-3.65-4.5-1.06-5.41-3.36-5.48-7.65-.11-6.85,1.35-13.54,1.21-20.38-.23-11.82-.6-23.68,.05-35.47,1.16-20.79-.03-41.61,1.13-62.34,.97-17.31-.26-34.62,.89-51.86,1.29-19.3-.16-38.62,1.07-57.85,1.17-18.31,.08-36.59,.91-54.86,.33-7.29,1.11-14.57,.89-21.88-.09-2.9,1.49-4.05,3.98-4.86,18.46-5.99,36.65-12.96,55.4-17.83,18.8-4.89,37.01-11.45,55.53-17.12,7.45-2.28,14.89-4.61,22.25-7.16,3.66-1.27,4.99-.19,4.52,3.47-1.93,15.09,.05,30.29-1.2,45.34-1.01,12.16-.04,24.28-.87,36.38-1.14,16.78-.06,33.62-1.16,50.35-.93,14.15,.19,28.3-.89,42.37-1.2,15.63,.09,31.3-1.1,46.85-.95,12.49,0,24.95-.85,37.37-1.12,16.45-.26,32.93-1.15,49.35-.47,8.64-.49,17.26-.85,26.87Zm-136.9,9.34c-.02,7.9-.04,8,7.29,9.72,4.5,1.06,9.23,1.34,13.6,2.76,13.25,4.31,26.96,6.77,40.4,10.33,19.82,5.26,39.79,9.93,59.66,14.98,5.46,1.39,12.08-3.03,12.28-8.74,.27-7.63,.39-15.25,.87-22.88,.95-15.08-.21-30.22,1.04-45.36,1.14-13.73-.19-27.55,.94-41.37,1.23-15.05-.19-30.22,1.05-45.36,1.16-14.23-.18-28.55,.96-42.87,1.22-15.39-.21-30.89,1.05-46.36,1.15-14.06,.03-28.21,.9-42.37,1.07-17.23,.04-34.57,1.2-51.85,.73-10.93-.92-21.93,.9-32.86,.48-2.92-1.51-2.33-3.17-1.81-6.48,2.05-12.92,4.22-19.42,6.2-18.53,5.64-36.72,12.26-55.53,17.12-18.43,4.76-36.34,11.56-54.47,17.46-2.21,.72-3.65,1.8-3.6,4.58,.11,6.63-.44,13.26-.77,19.88-.95,19.09,.32,38.22-1.01,57.36-1.25,17.9,.23,35.88-.97,53.86-1.26,18.89,.24,37.88-1.05,56.85-1.21,17.9,.28,35.88-.95,53.86-1.3,19.06,.82,38.25-1.2,56.86Z"/>
                    <path ref={outlineLogoRef} d="M1306.15,442.71c-5.82,0-11.64,.08-17.46-.02-8.01-.14-13.51-5.51-13.59-13.47-.12-11.81-.11-23.61,0-35.42,.07-8.02,5.5-13.51,13.46-13.59,11.81-.12,23.61-.11,35.42,0,8.02,.07,13.51,5.5,13.59,13.46,.12,11.81,.11,23.61,0,35.42-.07,8.02-5.5,13.44-13.46,13.6-5.98,.11-11.97,.02-17.96,.02Zm-.14-3.58s0-.01,0-.02c6.15,0,12.32,.21,18.46-.06,5.53-.25,9.15-3.68,9.45-9.06,.68-12.3,.66-24.62,.01-36.91-.29-5.45-3.82-8.93-9.31-9.22-12.13-.63-24.28-.62-36.42-.01-5.72,.29-9.2,3.82-9.49,9.57-.6,11.97-.59,23.95-.01,35.92,.29,6.03,3.77,9.47,9.84,9.74,5.81,.26,11.64,.06,17.46,.06Z"/>
                    <path ref={outlineLogoRef} d="M1508.53,411.32c0,6.33,.09,12.65-.02,18.98-.11,6.93-5.41,12.28-12.34,12.34-12.65,.1-25.3,.11-37.95,0-6.61-.06-12.02-5.47-12.08-12.08-.11-12.82-.12-25.64,.01-38.45,.07-6.34,5.48-11.76,11.82-11.82,12.98-.13,25.97-.14,38.95,.01,5.98,.07,11.41,5.58,11.56,11.56,.17,6.49,.04,12.98,.04,19.48Zm-31.15,28.77c5.99-.05,11.98,.24,17.94-.67,6.2-.94,9.28-3.75,9.92-9.98,1.22-11.94,1.21-23.92,.01-35.87-.63-6.3-3.64-9.39-9.82-10.03-12.11-1.25-24.26-1.26-36.36,.02-5.95,.63-9.02,3.7-9.64,9.64-1.28,12.11-1.27,24.26-.02,36.36,.64,6.17,3.76,8.93,10.02,9.87,5.96,.89,11.96,.59,17.94,.65Z"/>
                    <path ref={outlineLogoRef} d="M1420.52,409.03c0,5.32,.16,10.64-.04,15.96-.26,6.82-3.82,10.48-10.66,10.61-10.97,.21-21.94,.21-32.91,0-7.06-.13-10.64-3.84-10.76-11.02-.17-10.8-.18-21.61,.01-32.41,.13-7.11,3.78-10.75,10.91-10.87,10.8-.19,21.61-.19,32.41,0,7.34,.12,10.75,3.82,11.06,11.29,.01,.33,.02,.66,.02,1,0,5.15,0,10.31,0,15.46-.01,0-.03,0-.04,0Zm-51-.59c0,5.32-.15,10.64,.04,15.95,.19,5.1,2.7,7.72,7.79,7.81,10.63,.18,21.27,.18,31.91,0,5.1-.08,7.72-2.69,7.81-7.78,.18-10.63,.18-21.27,0-31.91-.08-5.1-2.69-7.72-7.78-7.81-10.63-.18-21.27-.18-31.91,0-5.1,.08-7.62,2.69-7.82,7.78-.2,5.31-.05,10.63-.05,15.95Z"/>
                    <path ref={outlineLogoRef} d="M639.92,261.78c-3.81,0-7.63-.02-11.44,0-2.58,.02-3.57-1.12-3.15-3.72,1.2-7.61,8.13-13.11,16.42-12.94,7.47,.15,12.7,5.12,13.78,12.93,.36,2.59-.58,3.74-3.16,3.73-4.14-.03-8.29,0-12.44,0Zm.09-2.66c2.82,0,5.66-.17,8.46,.04,3.8,.28,4.29-1.41,3.07-4.46-1.61-4.04-6.24-7.04-10.61-6.83-4.95,.24-10.92,4.48-11.95,8.45-.48,1.84-.09,2.87,2.07,2.81,2.99-.08,5.97-.02,8.96-.01Z"/>
                    <path ref={outlineLogoRef} d="M915.12,273.19c-.35-6.5,.05-12.33,2.85-17.92,2.77-5.55,7.15-7.12,12.98-7.26,5.98-.15,10.22,2.39,12.36,7.47,4.69,11.12,5.02,22.53,.49,33.89-2.51,6.3-7.6,8.48-13.9,8.41-6.19-.07-10.18-3.59-12.69-8.92-2.41-5.11-2.52-10.61-2.1-15.66Zm2.7,0c-.63,5,.25,10.04,2.35,14.84,1.91,4.36,5.5,6.9,10.1,6.91,4.4,.01,8.49-1.44,10.52-6.12,4.7-10.83,4.42-21.6-.34-32.28-1.79-4.02-5.04-5.91-9.58-5.77-4.47,.14-7.97,1.43-10.16,5.74-2.66,5.23-3.32,10.72-2.9,16.68Z"/>
                    <path ref={outlineLogoRef} d="M1307.65,273.61c.5,5.3-.75,10.56-2.84,15.71-2.47,6.06-7.27,8.32-13.38,8.46-6.21,.15-10.93-2.99-12.9-8.47-4.04-11.23-4.18-22.71,.46-33.96,2.58-6.27,7.77-7.35,13.87-7.33,5.85,.02,9.39,3.06,11.63,7.83,2.58,5.48,3.5,11.38,3.16,17.75Zm-28.87-.36c-.61,5.07,.28,10.1,2.39,14.9,1.85,4.19,5.33,6.63,9.68,6.78,3.95,.14,8.12-.79,10.19-4.91,5.76-11.48,5.58-23.16-.22-34.42-3.71-7.2-15.06-6.53-18.96,.6-2.9,5.31-3.55,10.98-3.09,17.05Z"/>
                    <path ref={outlineLogoRef} d="M1502.57,411.14c0,4.99,.19,9.99-.05,14.96-.35,7.26-3.29,10.33-10.55,10.53-9.8,.26-19.63,.27-29.43,0-7.2-.21-10.23-3.34-10.42-10.65-.25-9.64-.24-19.29,0-28.93,.18-7.51,3.29-10.61,10.8-10.8,9.64-.24,19.29-.25,28.93,0,7.46,.2,10.29,3.35,10.73,10.91,.02,.33,.03,.66,.03,1,0,4.32,0,8.65,0,12.97-.01,0-.03,0-.04,0Zm-24.9-22.43c-5.16,0-10.32-.15-15.47,.04-5.01,.18-7.46,2.47-7.56,7.38-.19,10.14-.18,20.3-.02,30.44,.08,5.01,2.45,7.46,7.38,7.56,10.14,.19,20.29,.19,30.44,.02,5.01-.08,7.46-2.46,7.56-7.38,.19-10.14,.19-20.3,.01-30.44-.09-5.17-2.54-7.42-7.87-7.6-4.82-.17-9.65-.04-14.47-.02Z"/>
                    <path ref={outlineLogoRef} d="M279.47,1c0,.83-.1,1.68,.01,2.5,1.03,7.61-.83,13.86-7.44,18.6-3.4,2.44-5.85,6.17-9.13,8.82-3.09,2.5-3.46,5.44-3.51,9.08-.28,19-.47,38.02-1.23,56.98-.58,14.35,.42,28.73-.85,42.99-1.33,15.02,.26,30.07-1.03,44.99-1.27,14.7,.29,29.4-.98,44-1.3,15.02,.28,30.07-1.03,44.99-1.23,14.03,.29,28.07-.96,42-1.35,15.02,.27,30.07-1.04,44.99-1.26,14.37,.12,28.71-.97,43-.6,7.85-.85,15.67-.79,23.51,.02,2.68-1.56,4.68-3.15,5.87-5.43,4.06-10.35,8.65-15.41,13.12-3.13,2.76-6.73,3.71-10.99,3.6-10.66-.28-21.35-.5-31.99,.03-10.08,.49-19.08-3.23-28.54-5.45-12.93-3.04-25.62-7.15-38.55-9.71-9.25-1.83-18.1-4.98-27.38-6.59-11.23-1.95-21.98-6.44-33.09-8.47-9-1.64-17.44-5.02-26.42-6.45-8.15-1.3-16.04-3.8-24.04-5.8-2.22-.56-2.5-1.3-2.49-3.14,.05-12-.77-24.07,.27-35.97,.86-9.88,.63-19.69,.69-29.52,.16-27.17,.84-54.33,1.41-81.48,.38-18.34-.51-36.72,.84-54.99,1.34-18.19-.33-36.39,.99-54.49,1.39-19.19,.3-38.34,.85-57.5,.03-1.17,.31-2.33,.48-3.5-4.3-1-8.67-.28-13-.5" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M188.98,424.45c9-4.05,8.33-11.84,8.69-19.99,.74-16.84-.04-33.72,1.05-50.49,.91-14.03-.34-28.07,.91-42,1.35-15.02-.27-30.07,1.04-44.99,1.26-14.37-.3-28.74,.97-43,1.37-15.36-.29-30.74,1.03-45.99,1.25-14.37-.3-28.74,.97-43,1.31-14.69-.27-29.41,1.03-43.99,1.28-14.37,.39-28.68,.7-43.01,.05-2.47,1.23-4.56,1.16-7-.07-2.71,.21-3.97-3.61-2.66-14.7,5.06-29.62,9.45-44.43,14.18-8.03,2.56-15.85,5.99-24.03,7.85-21.31,4.85-41.54,13.1-62.44,19.21-2.17,.63-4.55,.89-4.71,4.91-.9,22.83,.02,45.7-1.06,68.49-.89,18.69,.44,37.39-.94,55.99-1.31,17.7,.31,35.39-.96,53-1.39,19.35,.3,38.72-1.05,57.99-1.26,17.86-.12,35.69-.86,53.51-.22,5.33-1.32,10.62-.97,16,.13,2.09-.21,3.41,2.98,4.19,14.99,3.69,29.88,7.77,45.01,10.95,8.75,1.84,17.27,4.84,26.01,6.93,17.06,4.08,34.01,8.6,51.02,12.9,.63,.16,1.33,.02,1.99,.02" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M829.9,293.96c-13.02-.61-24.53-5.16-34.45-13.56-1.71-1.45-3.51-1.15-5.02-.8-1.78,.41-.99,2.49-1,3.86-.06,7,.12,14-.1,20.99-.09,2.92,.92,4.57,3.57,5.51,20.74,7.37,41.68,9.92,62.99,2.48,14.46-5.05,26.33-21.06,22.77-39.54-2.97-15.38-13.51-21.09-26.26-25.46-8.2-2.81-16.97-3.45-25.06-6.85-3.34-1.41-5.74-2.53-6.03-6.62-.3-4.11,1.9-6.12,5.1-7.48,13.29-5.64,25.29-1.28,36.97,5.01,2.31,1.24,4.23,3.21,6.55,4.39,2.26,1.15,4.57,1.58,4.5-2.43-.12-7.33-.2-14.67,.04-21.99,.11-3.5-2.42-4.21-4.59-4.94-17.19-5.78-34.84-7.55-52.42-2.88-13.3,3.53-24.03,11.71-26.82,25.86-3.92,19.86,5.23,33.75,24.79,39.89,7.55,2.37,15.25,4.36,23.01,5.89,2.5,.49,4.57,1.64,6.38,2.81,5.68,3.67,4.23,9.35-2.33,13.49-3.96,2.5-8.18,1.07-12.08,2.37" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M1089.86,305.96c-2.09,6.79-.98,8.5,5.5,8.5s13-.14,19.5,.06c2.81,.09,3.57-.76,3.56-3.56-.1-26-.1-51.99,0-77.99,.01-2.8-.75-3.64-3.56-3.56-7.16,.19-14.34,.24-21.49-.02-3.28-.12-4.12,.81-4.08,4.08,.17,16.66-.04,33.33,.17,49.99,.05,3.74-.96,6.21-4.6,6.94-3.44,.69-6.35,2.54-10.57,1.37-5.49-1.53-6.24-5.13-6.88-9.31-1.44-9.45-1.01-18.98-1.03-28.49-.02-7-.13-14,.06-21,.08-2.81-.75-3.64-3.57-3.57-6.99,.19-14,.19-20.99,0-2.81-.08-3.58,.76-3.56,3.56,.12,19.66-.51,39.35,.26,58.98,.52,13.09,8.77,24.35,24.79,24.7,9.62,.21,17.59-2.65,25-8.2" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M1378.83,237.97c2.09-6.79,.98-8.5-5.5-8.5s-13,.14-19.5-.06c-2.81-.09-3.57,.76-3.56,3.56,.1,26,.1,51.99,0,77.99-.01,2.8,.75,3.64,3.56,3.56,7.16-.19,14.34-.24,21.49,.02,3.28,.12,4.12-.81,4.08-4.08-.17-16.83-.02-33.66-.14-50.49-.02-2.95,.54-5.1,3.56-5.99,3.75-1.1,6.98-2.98,11.57-1.78,5.82,1.52,6.39,5.43,6.89,9.78,1.76,15.62,1.04,31.32,.92,46.99-.04,4.53,1.07,5.91,5.63,5.61,6.3-.43,12.66-.25,18.99-.04,2.81,.09,3.58-.76,3.56-3.56-.12-19.66,.41-39.34-.23-58.99-.42-13.15-10.31-24.41-24.83-24.69-9.58-.19-17.45,3.06-25,8.19" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M1179.35,295.46c-8.04,0-9.02-1.62-10.14-9.98-1.39-10.36-.33-20.69-.92-31.01-.19-3.28,.78-4.24,4.07-4.08,5.82,.28,11.67-.07,17.49,.14,2.81,.1,3.71-.75,3.56-3.57-.23-4.32-.23-8.67,0-12.99,.15-2.82-.75-3.66-3.56-3.57-5.99,.21-12-.08-17.99,.13-2.81,.1-3.67-.75-3.56-3.57,.21-5.66-.09-11.34,.13-16.99,.11-2.81-.75-3.64-3.56-3.57-6.99,.19-14,.19-20.99,0-2.81-.08-3.68,.75-3.56,3.57,.22,5.32-.18,10.68,.15,15.99,.23,3.7-.92,5.19-4.58,4.5-4.52-.84-6.11,.95-5.58,5.51,.4,3.45,.31,7.01,.01,10.48-.28,3.29,.81,4.62,4.07,4,4.97-.95,6.31,1.02,6.13,6.01-.41,11.65-.1,23.33-.14,34.99-.05,15.8,11.16,25.17,25,24.97,7.84-.12,15.69,.63,23.52-.87,3.2-.61,4.72-1.65,4.54-5.1-.23-4.32-.39-8.7,.04-12.99,.46-4.54-2.59-4.91-5-3.81-2.83,1.29-5.78,1.39-8.59,2.3" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M1397.33,439.95c-.12,6.03,3.4,6.71,8.5,6.61,15.09-.3,25.49-10.5,25.49-25.6,0-9-.64-18.06,.17-26.98,.92-10.15-13.53-24.56-23.68-23.69-9.6,.82-19.37,.82-28.97,0-10.15-.87-24.55,13.53-23.69,23.69,.82,9.6,.82,19.37,0,28.97-.87,10.15,13.56,24.98,23.68,23.58,6.44-.89,13.08,1.06,19.49-1.07" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M226.97,1c-8.91,.28-16.74,4.44-24.98,7.07-32.23,10.29-64.53,20.37-96.59,31.2-6.83,2.31-13.76,4.73-20.9,6.29-16.42,3.59-31.96,10.14-48.08,14.69-5.56,1.57-11.93,.35-17.93,.2-2.65-.06-4.77,.17-3.96,3.54,.67,2.8-1.4,3.76-3.08,4.42-3.38,1.31-6.96,2.09-10.46,3.09" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M1397.83,438.95c5.49-.56,11,.82,16.53-.9,5.82-1.81,8.97-5.28,9.32-12.61,.55-11.31,.68-22.69-.03-33.98-.57-9.05-4.28-12.77-13.34-13.34-11.29-.71-22.69-.71-33.97,0-9.05,.57-12.77,4.28-13.34,13.34-.71,11.29-.27,22.65-.13,33.98,.1,7.84,5.97,13.29,13.96,13.52,6.83,.19,13.69-.62,20.5,.48" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M1603.8,388.95c-.16-3.03,.56-5.96-2.4-8.61-5.12-4.56-9.47-10.24-17.09-10.35-11.33-.16-22.67-.17-33.99,0-9.5,.14-21.52,12.29-21.5,21.96,.02,11,.03,22,0,33-.03,9.71,12.29,22.03,22,22,11-.03,22,.01,33-.02,7.59-.02,11.96-5.42,17.12-9.85,3.25-2.79,2.79-5.84,2.88-9.14" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M462.94,249.97c-5.08-5.18-8.09-11.84-12.3-17.64-1.62-2.23-2.86-2.89-5.2-2.87-8.33,.04-16.66-.01-25,.04-1.37,0-3.17-.75-3.94,1-.62,1.41,.58,2.38,1.4,3.51,8.24,11.44,16.12,23.16,24.7,34.34,2.64,3.44,1.71,5.55-.18,8.1-9.07,12.28-18.22,24.5-27.39,36.8,2.36,2,4.75,1.13,6.92,1.17,7.16,.12,14.33-.02,21.5,.07,3.21,.04,5.34-.54,7.18-3.93,3.18-5.85,7.48-11.1,11.56-16.94,5.05,4.03,8.01,9.79,10.96,15.01,2.9,5.14,6.18,6.14,11.3,5.91,6.99-.31,14-.05,20.99-.11,1.37-.01,3.17,.75,3.94-1,.62-1.41-.58-2.38-1.4-3.51-8.41-11.61-16.47-23.48-25.2-34.84-2.4-3.13-1.58-4.77,.15-7.12,8.34-11.32,16.64-22.68,25.04-33.96,.84-1.12,2.03-2.1,1.4-3.51-.77-1.75-2.57-.95-3.94-1-2.66-.09-5.33-.03-8-.03h-20c-4.83,7-9.69,13.98-14.45,21.03-.39,.57-.05,1.63-.05,2.47" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M697.91,198.48c-2.4,.62-1.46,2.6-1.46,4-.05,36.33,0,72.66-.12,108.99-.01,3.26,.8,4.2,4.08,4.08,7.16-.26,14.33-.21,21.49-.02,2.81,.08,3.57-.76,3.56-3.56-.09-36.66-.09-73.32,0-109.99,0-2.8-.75-3.63-3.56-3.56-7.83,.18-15.66,.06-23.5,.06" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M990.88,198.48c-2.4,.62-1.46,2.6-1.46,4-.05,36.33,0,72.66-.12,108.99-.01,3.26,.8,4.2,4.08,4.08,7.16-.26,14.33-.21,21.49-.02,2.81,.08,3.57-.76,3.56-3.56-.09-36.66-.09-73.32,0-109.99,0-2.8-.75-3.63-3.56-3.56-7.83,.18-15.66,.06-23.5,.06" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M1205.85,198.48c-1.54,.21-1.52,1.32-1.51,2.5,.02,5.83,.11,11.67-.04,17.5-.06,2.37,.68,3.09,3.05,3.05,7.66-.13,15.33-.13,23,0,2.96,.05,4.12-1.07,4.04-4.04-.14-5.16-.19-10.34,.02-15.49,.12-2.81-.75-3.63-3.56-3.57-8.16,.18-16.33,.06-24.5,.06" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M332.96,203.47c-2.4,.62-1.46,2.6-1.46,4-.05,34.33,0,68.66-.12,102.99-.01,3.26,.81,4.1,4.08,4.08,23-.15,45.99-.15,68.99,0,3.26,.02,4.27-.8,4.08-4.08-.29-4.98-.29-10.01,0-14.99,.19-3.28-.81-4.14-4.08-4.09-12.83,.2-25.66-.06-38.49,.17-3.68,.07-4.81-.9-4.59-4.6,.33-5.48,.29-11,0-16.49-.17-3.28,.81-4.14,4.08-4.09,11.66,.21,23.33-.05,34.99,.16,3.27,.06,4.27-.8,4.08-4.09-.29-4.98-.29-10.01,0-14.99,.19-3.28-.81-4.14-4.08-4.09-11.66,.21-23.33-.05-34.99,.16-3.27,.06-4.09-.82-4.09-4.08,0-13-.13-13,12.59-13,10.17,0,20.33-.14,30.49,.08,3.27,.07,4.28-.8,4.08-4.09-.29-4.81-.33-9.68,0-14.49,.26-3.7-.91-4.62-4.58-4.59-23.5,.18-46.99,.09-70.49,.09" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M550.93,227.47c-7.13,2.31-14.38,3.83-20.62,8.85-9.06,7.28-13.07,16.57-15.02,27.62-1.52,8.63-.69,16.93,1.77,24.99,4.28,14.05,14.15,22.8,28.4,25.94,13.06,2.87,26.12,2.8,39.02-1.79,4.51-1.6,6.47-3.73,6.06-8.62-.44-5.13-.06-10.32-.11-15.49-.04-3.23-2.75-4.75-5.46-2.96-1.97,1.3-3.59,3.12-5.54,4.43-10.05,6.75-22.55,7.37-30.48-1.47-10.39-11.58-4.51-35.67,10.03-39.91,8.55-2.49,15.71,1.7,22.45,6.43,.95,.67,1.58,1.79,2.54,2.44,1.67,1.14,3.76,.78,5.45,.4,1.78-.41,.99-2.5,1-3.87,.07-5.33-.24-10.68,.11-15.99,.27-4.06-1.48-5.61-5.03-7.12-11.04-4.71-22.56-3.83-34.05-3.88" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M632.92,227.47c-7.72,1.58-15.04,4.25-20.95,9.56-8.24,7.4-12.2,16.84-13.78,27.91-1.34,9.46-.49,18.46,2.88,26.97,5.52,13.96,16.64,21.46,31.35,23.53,15.5,2.18,30.72,1.9,45.58-4.25,3.18-1.32,3.46-2.63,3.43-5.22-.07-5.17,.03-10.33-.05-15.5-.02-1.6,.71-3.49-1.46-4.52-2-.95-3.29-.26-5.07,.94-7.95,5.37-16.66,9.12-26.43,8.47-7.56-.5-15.09-1.81-19.56-9.35-1.34-2.26-1.24-4.71-2.09-6.97,2.77-.91,5.48-.54,8.15-.55,14.5-.03,29-.12,43.49,.07,3.27,.04,4.13-.81,4.09-4.08-.13-10.45,.54-20.97-5.08-30.5-6.08-10.3-15.05-15.92-27-16.45-5.65-.25-11.33-.04-17-.04" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M638.92,246.47c-5.64,1.77-10.11,4.43-11.72,10.94-.64,2.57-.9,3.07,1.72,3.06,7.67-.02,15.33-.01,23,0,1.74,0,2.7,.08,2.12-2.53-1.69-7.62-6.72-12.34-14.62-11.47" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M926.88,249.47c-7.45,1.98-9.62,8.5-10.2,14.53-.69,7.2-1.49,14.72,1.13,21.99,2.43,6.73,6.55,10.48,12.56,10.48,6.83,0,10.42-2.92,13.04-9.98,3.07-8.28,3-16.91,.7-24.93-2.59-9.04-4.6-13.1-16.73-12.07" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M1287.84,249.47c-7.45,1.98-9.62,8.5-10.2,14.53-.69,7.2-1.49,14.72,1.13,21.99,2.43,6.73,6.55,10.48,12.56,10.48,6.83,0,10.42-2.92,13.04-9.98,3.07-8.28,3-16.91,.7-24.93-2.59-9.04-4.6-13.1-16.73-12.07" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M1556.81,252.47c-5.4,1.83-11.16,2.39-16.04,5.94-12.42,9-17.87,21.32-15.21,36.01,2.55,14.07,11.51,24.42,25.8,27.31,20.96,4.24,37.92-3.43,43.43-25.4,4.53-18.07-3.33-38.43-28.46-42.99-3.04-.55-6-.78-9.02-.87" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M1205.85,230.47c-2.4,.62-1.46,2.6-1.46,4-.05,25.66,.02,51.33-.12,76.99-.02,3.26,.8,4.2,4.08,4.08,7.16-.26,14.33-.21,21.49-.02,2.81,.08,3.57-.76,3.56-3.56-.1-26-.1-51.99,0-77.99,.01-2.8-.75-3.63-3.56-3.56-7.83,.18-15.66,.06-23.5,.06" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M1474.82,227.47c-6.32,1.72-12.5,3.46-18.08,7.39-8.54,6.02-12.28,14.06-11.32,24.1,1.01,10.55,6.74,17.83,16.89,21.54,6.35,2.32,13.06,3.1,19.51,4.96,1.32,.38,2.79,.43,3.97,1.06,1.74,.93,3.61,1.92,3.55,4.44-.07,2.7-1.87,2.8-3.96,3.64-12.13,4.91-22-.91-32.04-6.17-.93-.49-1.62-1.42-2.52-1.96-3.84-2.29-5.44-1.41-5.48,2.98-.04,4.5,0,9,0,13.5,0,8.32,.44,8.01,8.45,10.65,10.9,3.59,22.04,3.48,33.03,2.64,13.34-1.03,24.78-6.63,30.44-19.81,5.94-13.85-3.31-30.51-18.43-33.97-5.83-1.33-11.65-2.72-17.51-3.94-.86-.18-1.37-.84-2-.99-2.58-.6-5-2.27-4.75-4.54,.28-2.55,3.22-3.68,5.76-4.55,7.01-2.41,13.59-.1,19.95,2.2,2.81,1.02,5.75,2.48,8.14,4.75,1.47,1.4,4.02,1.72,5.87,.96,2.07-.84,.92-3.2,1-4.88,.16-3.16,.05-6.33,.05-9.5,0-10.02-.25-9.95-9.98-12.58-9.94-2.68-19.99-1.67-30.02-1.92" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M1284.84,227.47c-4.17,1-8.61,1.38-12.45,3.11-14.51,6.55-22.33,17.8-24.23,33.86-1.4,11.87-.35,23,5.23,33.49,6.75,12.7,17.89,18.86,31.95,19.43,9.65,.39,19.41,.97,28.52-3.85,13.19-6.98,19.92-18.3,21.28-32.56,.91-9.5,1.09-19.28-2.8-28.48-5.84-13.82-15.85-22.01-30.98-24.13-5.37-.75-10.67-.71-16.02-.87" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M1287.84,373.45c-9.24,3.18-15.85,9.01-19.08,18.47-.54,1.59-.37,3.04-.38,4.53-.07,9.16-.04,18.33-.04,27.5,0,10.86,6.25,20.4,15.99,24.51,3.06,1.29,6.21,1.57,9.5,1.53,8.33-.12,16.74-.76,24.98,.14,11.33,1.24,27.27-13.77,25.74-25.7-1.15-9.02-.87-18.36-.06-27.45,.91-10.15-13.53-24.48-23.68-23.7-10.77,.83-21.65,.19-32.48,.19" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M1458.82,373.45c-9.24,3.18-15.85,9.01-19.08,18.47-.54,1.59-.37,3.04-.38,4.53-.07,9.16-.04,18.33-.04,27.5,0,10.86,6.25,20.4,15.99,24.51,3.06,1.29,6.21,1.57,9.5,1.53,8.33-.12,16.74-.76,24.98,.14,11.33,1.24,27.27-13.77,25.74-25.7-1.15-9.02-.87-18.36-.06-27.45,.91-10.15-13.53-24.48-23.68-23.7-10.77,.83-21.65,.19-32.48,.19" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M923.88,227.47c-4.17,1-8.61,1.38-12.45,3.11-14.51,6.55-22.33,17.8-24.23,33.86-1.4,11.87-.35,23,5.23,33.49,6.75,12.7,17.89,18.86,31.95,19.43,9.65,.39,19.41,.97,28.52-3.85,13.19-6.98,19.92-18.3,21.28-32.56,.91-9.5,1.09-19.28-2.8-28.48-5.84-13.82-15.85-22.01-30.98-24.13-5.37-.75-10.67-.71-16.02-.87" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M1463.82,381.45c-2.62,.97-5.43-.08-7.98,.58-4.61,1.2-7.78,6.33-8.06,11.41-.67,12-.62,23.99-.01,36,.35,6.84,4.71,11.2,11.54,11.54,12.01,.61,23.99,.61,36,0,6.84-.35,11.2-4.71,11.54-11.54,.61-12.01,.61-23.99,0-36-.35-6.84-4.71-11.12-11.54-11.56-10.34-.66-20.67-.27-31-.43" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M1285.84,381.95c-7.19,2.48-9.35,7.73-9.09,15,.38,10.49,.52,21.02-.06,31.48-.36,6.57,6.09,12.99,12.66,12.66,11.3-.56,22.68-.56,33.98,0,6.57,.33,12.99-6.1,12.66-12.66-.56-11.3-.56-22.68,0-33.98,.33-6.57-6.1-12.96-12.66-12.66-12.31,.55-24.66,.16-36.99,.16" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M1374.83,382.95c-5.5,1.65-7.19,5.53-7.07,11,.23,9.99,.38,20.01-.05,29.99-.25,5.77,4.36,10.36,10.12,10.12,10.31-.42,20.67-.42,30.99,0,5.77,.24,10.36-4.36,10.12-10.12-.42-10.31-.42-20.67,0-30.99,.24-5.77-4.36-10.34-10.12-10.12-11.15,.41-22.33,.12-33.49,.12" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path ref={outlineLogoRef} d="M1460.32,387.45c-5.5,1.65-7.21,5.52-7.06,11,.24,8.99,.39,18.01-.05,26.99-.28,5.77,4.35,10.39,10.12,10.12,9.31-.44,18.67-.44,27.99,0,5.77,.27,10.39-4.35,10.12-10.12-.44-9.31-.44-18.67,0-27.99,.27-5.77-4.35-10.37-10.12-10.12-10.15,.43-20.33,.12-30.49,.12" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
            </svg>  */}
            
        </div>
    )
}

export default Logo;